import { useContext, useEffect, useState } from 'react';
import { FormContext } from '@src/common/util/validation/form';
import canObtainConsent from '@src/features/consent/utils/canObtainConsent';
import {
	CanObtainConsentResponse,
	ConsentForm,
	ConsentResponse,
	ConsentType,
	updateConsent,
} from '@src/features/consent/consentUtil';
import { ListFormNames } from '@cappex/constants';

const useTourAIConsent = active => {
	const { getValue, setFormValue } = useContext(FormContext);
	const [tourAIConsentRes, setTourAIConsentRes] = useState<CanObtainConsentResponse>();
	const [loadingConsent, setLoadingConsent] = useState(true);

	useEffect(() => {
		if (active && !tourAIConsentRes?.canObtainConsent) {
			canObtainConsent(ConsentType.TOUR_AI, setTourAIConsentRes, () => setLoadingConsent(false));
		}
	}, [active, tourAIConsentRes?.canObtainConsent]);

	useEffect(() => {
		if (tourAIConsentRes?.canObtainConsent) {
			const currentConsents =
				(getValue(ListFormNames.consents) as { consents: ConsentForm[] })?.consents || [];

			const updatedConsents = updateConsent({
				consents: currentConsents,
				consentTextId: tourAIConsentRes.consentTextId,
				consentTypeId: tourAIConsentRes.consentTypeId,
				responseTypeId: ConsentResponse.YES,
			});

			setFormValue(ListFormNames.consents, updatedConsents);
		}
	}, [getValue, setFormValue, tourAIConsentRes]);

	return {
		tourAIConsentRes,
		loadingConsent,
	};
};

export default useTourAIConsent;
