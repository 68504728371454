import * as R from 'ramda';
import React, {
	ComponentType,
	createContext,
	Dispatch,
	RefObject,
	useReducer,
	useCallback,
	useMemo,
} from 'react';
import { FormNames, ListFormNames } from '@cappex/constants';
import useAllUrlParams from '../../hooks/useAllUrlParams';
import { Parent, TourPersonalization } from '@src/common/constants/types';

export interface ValidationField {
	path: string[];
	fieldName: string;
	value: FormFields;
	error: string;
	fieldRef: RefObject<HTMLElement>;
	validator?: (value: FormFields, verifies: FormFields) => string;
	verifiedBy?: string;
	verifies?: string;
	removeUpdateFields?: boolean;
}

export interface ValidationState {
	[key: string]: ValidationField;
}

export enum ValidationActions {
	SET_VALUE = 'SET_VALUE',
	SET_VALUES = 'SET_VALUES',
	SET_ERROR = 'SET_ERROR',
	SET_VALIDATOR = 'SET_VALIDATOR',
	SET_FORM_ERRORS = 'SET_FORM_ERRORS',
	INIT = 'INIT',
	UNMOUNT = 'UNMOUNT',
}

export type FormValue = TourPersonalization[] | Parent[] | string[] | string;

export type FormFields = Partial<
	{ [key in ListFormNames]: FormValue } & { [key in FormNames]: string }
>;

export type FormErrors = Partial<{ [key in ListFormNames | FormNames]: string[] }>;

export interface ValidationAction {
	type: ValidationActions;
	field?: string;
	value?: FormFields;
	validator?: (value: FormFields, verifies: FormFields) => string;
	error?: string[];
	init?: ValidationField;
	formErrors?: FormErrors;
	multiInit?: boolean;
}

export interface FormContextValue {
	formState: ValidationState;
	dispatch: Dispatch<ValidationAction>;
	getFormValues: (
		convertBlanksToNull?: boolean,
		addUpdateFieldForNonBlanks?: boolean
	) => FormFields;
	setFormValue: (fieldName: string, fieldValue: any) => void;
	getValue: (fieldName: string) => object;
	getError: (fieldName: string) => string;
	setFormErrors: (formErrors: FormFields | FormErrors) => void;
	setError: (field: string, error: string[]) => void;
	setValues: (value: FormFields) => void;
}

const defaultFunction = () => {
	throw new Error('Using default value instead of context');
};

export const FormContext = createContext<FormContextValue>({
	formState: {},
	dispatch: defaultFunction,
	getFormValues: defaultFunction,
	setFormValue: defaultFunction,
	getValue: defaultFunction,
	getError: defaultFunction,
	setFormErrors: defaultFunction,
	setError: defaultFunction,
	setValues: defaultFunction,
});

export const createReducer = (
	paramMapping: { [key in FormNames & ListFormNames]?: string },
	urlParams: { [key in string]: string }
) => (state: ValidationState, action: ValidationAction) => {
	const updatedFieldLens = R.lensPath([action.field]);
	const updatedFieldValueLens = R.lensPath([action.field, 'value']);
	const updatedFieldErrorLens = R.lensPath([action.field, 'error']);
	const updatedFieldValidatorLens = R.lensPath([action.field, 'validator']);

	switch (action.type) {
		case ValidationActions.SET_VALUE: {
			return R.set(updatedFieldErrorLens, '', R.set(updatedFieldValueLens, action.value, state));
		}
		case ValidationActions.SET_VALUES: {
			return Object.keys(action.value).reduce(
				(acc, key) => {
					const field = acc[key];
					if (field) {
						field.value = { [key]: action.value[key] };
					}
					return acc;
				},
				{ ...state }
			);
		}
		case ValidationActions.SET_ERROR: {
			return R.set(updatedFieldErrorLens, action.error, state);
		}
		case ValidationActions.SET_FORM_ERRORS: {
			return Object.keys(action.formErrors).reduce(
				(acc, errorKey) => {
					const stateField = acc[errorKey];

					// Backend can return errors for fields that are not kept in this state
					if (stateField) {
						stateField.error = action.formErrors[errorKey];
					}
					return acc;
				},
				{ ...state }
			);
		}
		case ValidationActions.SET_VALIDATOR:
			if (state[action.field]) {
				return R.set(updatedFieldValidatorLens, action.validator, state);
			}
			return state;
		case ValidationActions.INIT: {
			if (
				state[action.field] &&
				R.equals(state[action.field].path, action.init.path) &&
				!action.multiInit
			) {
				throw new Error(
					`Field with the name ${action.field} at ${action.init.path} is being initialized multiple times`
				);
			}

			const fieldName = action.init?.fieldName;
			const valueFromUrl = urlParams[paramMapping[fieldName]];
			const mutableValue = action.init?.value;
			if (
				(R.isNil(mutableValue?.[fieldName]) || R.isEmpty(mutableValue?.[fieldName])) &&
				!R.isNil(valueFromUrl)
			) {
				mutableValue[fieldName] = valueFromUrl;
			}
			const init = { ...action.init, value: mutableValue };

			const i = R.set(updatedFieldLens, init, state);
			return i;
		}
		case ValidationActions.UNMOUNT: {
			const { [action.field]: _, ...newState } = state;
			return newState;
		}
		default:
			return state;
	}
};

const findTopMostRef = R.reduce<ValidationField, HTMLElement>((acc, field) => {
	const accOffsetTop = R.path<number>(['offsetTop'], acc);
	const fieldOffsetTop = R.path<number>(['fieldRef', 'current', 'offsetTop'], field);

	const accValue = R.isNil(accOffsetTop) ? Number.MAX_SAFE_INTEGER : accOffsetTop;
	const fieldValue = R.isNil(fieldOffsetTop) ? Number.MAX_SAFE_INTEGER : fieldOffsetTop;

	return fieldValue < accValue ? field.fieldRef.current : acc;
}, {} as HTMLDivElement);

export const scrollToError = (formErrors: FormErrors, formState: ValidationState) => {
	const fieldsInError = R.filter(R.complement(R.isEmpty), formErrors) as FormFields;

	if (R.isEmpty(fieldsInError)) {
		return;
	}

	const errorValidationFields = R.props<string, ValidationField>(R.keys(fieldsInError), formState);

	const highestField = findTopMostRef(errorValidationFields);
	if (R.isNil(highestField) || R.isEmpty(highestField)) {
		// eslint-disable-next-line no-console
		console.warn(
			"highestField is NULL or EMPTY cannot scroll to highest field! Perhaps 'ref' is not added to wrapper."
		);
	} else {
		highestField.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}
};

export const withFormValidation = <T extends object>(
	Wrapped: ComponentType<T>,
	paramMapping: { [key in FormNames & ListFormNames]?: string } = {}
) => (props?: T) => {
	const urlParameters = useAllUrlParams();
	const [formState, dispatch] = useReducer(createReducer(paramMapping || {}, urlParameters), {});

	// Disable eslint complaints about reassigning a parameter, since that's how this accumulator works
	/* eslint-disable no-param-reassign */
	const getFormValues = useCallback(
		(
			convertBlanksToNull = false,
			addUpdateFieldForNonBlanks = false,
			addUpdateFieldForBlanks = false
		) =>
			Object.keys(formState).reduce((acc, stateKey) => {
				Object.keys(formState[stateKey].value).forEach(valueKey => {
					const accLensValue = R.lensPath([...formState[stateKey].path, valueKey]);
					const accUpdateFieldLensValue = R.lensPath([
						...formState[stateKey].path,
						`update${valueKey.charAt(0).toUpperCase()}${valueKey.slice(1)}`,
					]);

					if (convertBlanksToNull && R.isEmpty(formState[stateKey].value[valueKey])) {
						acc = R.set(accLensValue, null, acc);
						if (addUpdateFieldForBlanks && !formState[stateKey].removeUpdateFields) {
							acc = R.set(accUpdateFieldLensValue, true, acc);
						}
					} else {
						acc = R.set(accLensValue, formState[stateKey].value[valueKey], acc);
						if (addUpdateFieldForNonBlanks && !formState[stateKey].removeUpdateFields) {
							acc = R.set(accUpdateFieldLensValue, true, acc);
						}
					}
				});
				return acc;
			}, {}),
		[formState]
	);
	/* eslint-enable no-param-reassign */

	const setFormValue = useCallback(
		(fieldName: string, fieldValue: any) => {
			formState[fieldName].value = { [fieldName]: fieldValue };
		},
		[formState]
	);

	const getValue = useCallback(
		(fieldName: string) => (formState[fieldName] ? formState[fieldName].value : {}),
		[formState]
	);

	const getError = useCallback(
		(fieldName: string): string => (formState[fieldName] ? formState[fieldName].error[0] : ''),
		[formState]
	);

	const setFormErrors = useCallback(
		(formErrors: FormErrors) => {
			dispatch({
				type: ValidationActions.SET_FORM_ERRORS,
				formErrors,
			});

			scrollToError(formErrors, formState);
		},
		[dispatch, formState]
	);

	const setValues = useCallback(
		(value: FormFields) => {
			dispatch({
				type: ValidationActions.SET_VALUES,
				value,
			});
		},
		[dispatch]
	);

	const setError = useCallback(
		(field: string, error: string[]) => {
			dispatch({
				type: ValidationActions.SET_ERROR,
				field,
				error,
			});
		},
		[dispatch]
	);

	const value = useMemo(
		() => ({
			formState,
			dispatch,
			getFormValues,
			setFormValue,
			getValue,
			getError,
			setFormErrors,
			setError,
			setValues,
		}),
		[
			formState,
			dispatch,
			getFormValues,
			setFormValue,
			getValue,
			getError,
			setFormErrors,
			setError,
			setValues,
		]
	);

	return (
		<FormContext.Provider value={value}>
			<Wrapped {...props} />
		</FormContext.Provider>
	);
};
