import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import getConsentText from '@util/consent/getConsentText';
import { ListFormNames } from '@cappex/constants';
import { FormContext } from '@util/validation/form';
import {
	ConsentForm,
	ConsentResponse,
	ConsentType,
	updateConsent,
} from '@src/features/consent/consentUtil';
import { Markdown } from '../constants/Markdown';

const useStyles = makeStyles(() => ({
	checkbox: {
		alignSelf: 'flex-start',
		marginTop: '4px',
	},
}));

interface Props {
	consentTextId?: string;
	consentTypeId?: ConsentType;
	consentText?: string;
	hasValidCorrespondingInput?: boolean;
	allowNoResponse?: boolean;
	addInitialValueOnlyWhenActive?: boolean;
	active?: boolean;
}

const ConsentCheckboxAndText: FC<Props> = ({
	consentTextId,
	consentTypeId,
	hasValidCorrespondingInput,
	consentText,
	allowNoResponse,
	addInitialValueOnlyWhenActive,
	active,
}) => {
	const classes = useStyles();
	const [text, setConsentText] = useState(consentText);
	const { getValue, setFormValue } = useContext(FormContext);

	useEffect(() => {
		!text &&
			getConsentText(consentTextId, consentTypeId).then(consent => setConsentText(consent.text));
	}, [text, consentTextId, consentTypeId]);

	const getResponseTypeId = useCallback(
		(checked: boolean) => {
			if (checked) return ConsentResponse.YES;
			if (allowNoResponse) return ConsentResponse.NO_RESPONSE;
			return ConsentResponse.NO;
		},
		[allowNoResponse]
	);

	const handleConsentChecked = useCallback(
		(checked: boolean) => {
			const currentConsents =
				(getValue(ListFormNames.consents) as { consents: ConsentForm[] })?.consents || [];

			const updatedConsents = updateConsent({
				consents: currentConsents,
				consentTextId,
				consentTypeId,
				responseTypeId: getResponseTypeId(checked),
				isValid: hasValidCorrespondingInput,
			});

			setFormValue(ListFormNames.consents, updatedConsents);
		},
		[
			consentTextId,
			consentTypeId,
			getResponseTypeId,
			getValue,
			hasValidCorrespondingInput,
			setFormValue,
		]
	);

	useEffect(() => {
		if (allowNoResponse) return;
		const currentConsents = (getValue(ListFormNames.consents) as unknown) as {
			consents: ConsentForm[];
		};
		const hasConsentInForm = currentConsents.consents?.some(
			consentValue => consentValue.consentTextId === consentTextId
		);
		if (hasConsentInForm) return;
		!addInitialValueOnlyWhenActive || (active && handleConsentChecked(false));
	}, [
		active,
		addInitialValueOnlyWhenActive,
		allowNoResponse,
		consentTextId,
		getValue,
		handleConsentChecked,
	]);

	return text ? (
		<FormControlLabel
			control={
				<Checkbox
					className={classes.checkbox}
					id="consentCheckBox"
					onChange={(_, val) => handleConsentChecked(val)}
				/>
			}
			labelPlacement="end"
			label={<Markdown variant="caption" source={text} />}
		/>
	) : null;
};

export default ConsentCheckboxAndText;
