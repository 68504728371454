import { useContext, useEffect, useState } from 'react';
import { FormContext } from '@src/common/util/validation/form';
import canObtainConsent from '@src/features/consent/utils/canObtainConsent';
import {
	CanObtainConsentResponse,
	ConsentForm,
	ConsentResponse,
	ConsentType,
	updateConsent,
} from '@src/features/consent/consentUtil';
import { ListFormNames } from '@cappex/constants';

const useInquiryConsent = active => {
	const { getValue, setFormValue } = useContext(FormContext);
	const [inquiryConsentRes, setInquiryConsentRes] = useState<CanObtainConsentResponse>();
	const [loadingConsent, setLoadingConsent] = useState(true);

	useEffect(() => {
		if (active && !inquiryConsentRes?.canObtainConsent) {
			canObtainConsent(ConsentType.INQUIRY_CONSENT, setInquiryConsentRes, () =>
				setLoadingConsent(false)
			);
		}
	}, [active, inquiryConsentRes?.canObtainConsent]);

	useEffect(() => {
		if (inquiryConsentRes?.canObtainConsent) {
			const currentConsents =
				(getValue(ListFormNames.consents) as { consents: ConsentForm[] })?.consents || [];

			const updatedConsents = updateConsent({
				consents: currentConsents,
				consentTextId: inquiryConsentRes.consentTextId,
				consentTypeId: inquiryConsentRes.consentTypeId,
				responseTypeId: ConsentResponse.YES,
			});

			setFormValue(ListFormNames.consents, updatedConsents);
		}
	}, [getValue, setFormValue, inquiryConsentRes]);

	return {
		inquiryConsentRes,
		loadingConsent,
	};
};

export default useInquiryConsent;
