import React, { ComponentType, useMemo } from 'react';
import AddressBlock from '@src/common/components/AddressBlock';
import AnnualFamilyIncomeSelect from '@src/common/components/AnnualFamilyIncomeSelect';
import BirthDateInput from '@src/common/components/BirthDateInput';
import CboFormInput from '@src/common/components/CboFormInput';
import ClassRankSelect from '@src/common/components/ClassRankSelect';
import CompositeTestScoresInput from '@src/common/components/CompositeTestScoresInput';
import CourseRigorSelect from '@src/common/components/CourseRigorSelect';
import CurrentCollegeInput from '@src/common/components/CurrentCollegeInput';
import EmailPasswordForm from '@src/common/components/EmailPasswordForm';
import EthnicityCheckboxList from '@src/common/components/EthnicityCheckboxList';
import GenderSelect from '@src/common/components/GenderSelect';
import MostRecentDegree from '@src/common/components/MostRecentDegree';
import Enrolled from '@src/common/components/Enrolled';
import GradDateInput from '@src/common/components/GradDateInput';
import SeekingMBA from '@src/common/components/SeekingMBA';
import SeekingTestOptionalAdmissions from '@src/common/components/SeekingTestOptionalAdmissions';
import HighSchoolIndexSearch from '@src/common/components/HighSchoolIndexSearch';
import MajorFormInput from '@src/common/components/MajorFormInput';
import MultiCollegeInput from '@src/common/components/MultiCollegeInput';
import NameForm from '@src/common/components/NameForm';
import PhoneNumberInput from '@src/common/components/PhoneNumberInput';
import StartTermInput from '@src/common/components/StartTermInput';
import UnweightedGpaInput from '@src/common/components/UnweightedGpaInput';
import WeightedGpaInput from '@src/common/components/WeightedGpaInput';
import ZipCodeInput from '@src/common/components/ZipCodeInput';
import { ListSubForm } from '@src/common/components/BaseValidationForm';
import { Grid } from '@material-ui/core';
import { FlexStepConfig } from '../constants/types';
import { ComponentName, FormNames, ListFormNames } from '@cappex/constants';
import GenericImageSelect from '@src/common/util/quiz/components/GenericImageSelect';
import GenericTextSelect from '@src/common/util/quiz/components/GenericTextSelect';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTypeTrackingValue,
} from '@src/common/util/studentcollege/constants';
import HiddenFlexInput from '@src/common/components/HiddenFlexInput';
import CompositeGpaInput from '@src/common/components/CompositeGpaInput';
import StartTimeframeSelect from '@src/common/components/StartTimeframeSelect';
import DesiredCompletionTimeFrameSelect from '@src/common/components/DesiredCompletionTimeframeSelect';
import ModalitySelect from '@src/common/components/ModalitySelect';
import LevelOfDegreeSeekingSelect from '@src/common/components/LevelOfDegreeSeekingSelect';
import HeaderSubtext from '@src/common/components/HeaderSubtext';
import YearsExperienceInput from '@src/common/components/YearsExperienceInput';
import GMATTotalInput from '@src/common/components/GMATTotalInput';
import GreQuantInput from '@src/common/components/GreQuantInput';
import GreVerbalInput from '@src/common/components/GreVerbalInput';
import GreWritingInput from '@src/common/components/GreWritingInput';
import GreCompositeInput from '@src/common/components/GreCompositeInput';
import EmailInput from '@common/components/EmailInput';
import FirstGenerationStudent from '@common/components/FirstGenerationStudent';
import OpenToTransfer from '@common/components/OpenToTransfer';
import RegStudentSelectType from '@common/components/RegStudentTypeSelect';
import SizeFormComponent from '@src/features/collegepreferences/components/SizeFormComponent';
import LocationDistanceFormComponent from '@src/features/collegepreferences/components/LocationDistanceFormComponent';
import CampusSettingComponent from '@src/features/collegepreferences/components/CampusSettingComponent';
import evaluatePredicate from '@src/common/util/form/evaluatePredicate';
import { ValidationState } from '@src/common/util/validation/form';
import CssHideWhen from '@src/common/components/CssHideWhen';
import TransferTermInput from '@src/common/components/TransferTermInput';
import ParentInput from '@src/common/components/ParentInput';
import AttendReligiousSelect from '@src/common/components/AttendReligiousSelect';
import StudyAbroadSelect from '@src/common/components/StudyAbroadSelect';
import AthleticsInput from '@src/common/components/AthleticsInput';
import ExtracurricularsInput from '@src/common/components/ExtracurricularsInputs';
import TourPersonalization from '@src/common/components/TourPersonalization';

const nest = (list: string[], Component: ComponentType, specialProps: any = {}) => (props: any) => (
	<ListSubForm name={list} distinct>
		<Component {...props} {...specialProps} />
	</ListSubForm>
);

const withProps = (Component: ComponentType, specialProps: any = {}) => (props: any) => (
	<Component {...props} {...specialProps} />
);

const mapToComponent = (name: ComponentName) => {
	switch (name) {
		case ComponentName.ADDRESS:
			return nest(['student', FormNames.studentAddressForm], AddressBlock);
		case ComponentName.ANNUAL_FAMILY_INCOME:
			return nest(['student', FormNames.studentFinancialForm], AnnualFamilyIncomeSelect, {
				name: FormNames.annualFamilyIncomeLevelId,
			});
		case ComponentName.ATHLETICS:
			return nest(['student', FormNames.studentAthleticForm], AthleticsInput, {
				name: ListFormNames.studentAthletics,
			});
		case ComponentName.ATTEND_RELIGIOUS:
			return nest(['student', FormNames.studentInfoForm], AttendReligiousSelect, {
				name: FormNames.attendReligiousId,
			});
		case ComponentName.BIRTH_DATE:
			return nest(['student', FormNames.studentDataForm], BirthDateInput);
		case ComponentName.CAMPUS_SETTING:
			return nest(['collegePreferences'], CampusSettingComponent, {
				name: ListFormNames.townSizeIds,
			});
		case ComponentName.CBO_SEARCH:
			return nest(['student', FormNames.studentCboForm], CboFormInput, {
				name: ListFormNames.studentCbos,
			});
		case ComponentName.CLASS_RANK:
			return nest(['student', FormNames.studentHighSchoolDataForm], ClassRankSelect, {
				name: FormNames.classRankId,
			});
		case ComponentName.COLLEGE_DISTANCE:
			return nest(['collegePreferences'], LocationDistanceFormComponent, {
				name: FormNames.locationCollegePreference,
			});
		case ComponentName.COLLEGE_GPA:
			return nest(['student', FormNames.studentCollegeDataForm], UnweightedGpaInput, {
				name: FormNames.collegeGpa,
			});
		case ComponentName.COLLEGE_SIZE:
			return nest(['collegePreferences'], SizeFormComponent, {
				name: ListFormNames.collegeSizeIds,
			});
		case ComponentName.COLLEGES:
			return withProps(MultiCollegeInput, {
				typeTrackingValue: StudentCollegeListTypeTrackingValue.SEARCH_BAR,
				locationTrackingValue: StudentCollegeListLocationTrackingValue.REGISTRATION_PATH,
			});
		case ComponentName.COMPOSITE_GPA:
			return CompositeGpaInput;
		case ComponentName.COMPOSITE_TEST_SCORES:
			return nest(['student', FormNames.studentTestScoreForm], CompositeTestScoresInput); // maybe need other inputs?
		case ComponentName.COURSE_RIGOR:
			return nest(['student', FormNames.studentHighSchoolDataForm], CourseRigorSelect, {
				name: FormNames.courseRigorId,
			});
		case ComponentName.CURRENT_COLLEGE:
			return nest(['student', FormNames.studentCollegeDataForm], CurrentCollegeInput);
		case ComponentName.DESIRED_COMPLETION_TIMEFRAME:
			return nest(['student', FormNames.studentInfoForm], DesiredCompletionTimeFrameSelect, {
				name: FormNames.desiredCompletionTimeframeId,
			});
		case ComponentName.EMAIL_INPUT:
			return nest(['accountInfo'], EmailInput);
		case ComponentName.EMAIL_PASSWORD:
			return nest(['accountInfo'], EmailPasswordForm);
		case ComponentName.ENROLLED:
			return nest(['student', FormNames.studentInfoForm], Enrolled);
		case ComponentName.ETHNICITY:
			return nest(['student', FormNames.studentEthnicitiesForm], EthnicityCheckboxList);
		case ComponentName.EXTRACURRICULARS:
			return nest(['student', FormNames.studentExtracurricularForm], ExtracurricularsInput, {
				name: ListFormNames.studentExtracurriculars,
			});
		case ComponentName.FIRST_GENERATION_STUDENT:
			return nest(['student', FormNames.studentCollegeDataForm], FirstGenerationStudent);
		case ComponentName.GENDER:
			return nest(['student', FormNames.studentDataForm], GenderSelect);
		case ComponentName.GENERIC_IMAGE_SELECT:
		case ComponentName.QUIZ_IMAGE:
			return nest(
				['student', FormNames.studentQuestionAnswerForm, FormNames.questionsAndAnswers],
				GenericImageSelect
			);
		case ComponentName.GENERIC_TEXT_SELECT:
		case ComponentName.QUIZ_TEXT:
			return nest(
				['student', FormNames.studentQuestionAnswerForm, FormNames.questionsAndAnswers],
				GenericTextSelect
			);
		case ComponentName.GMAT_TOTAL:
			return nest(['student', FormNames.studentTestScoreForm], GMATTotalInput, {
				name: FormNames.gmatTotal,
			});
		case ComponentName.GRAD_DATE:
			return nest(['student', FormNames.studentHighSchoolDataForm], GradDateInput);
		case ComponentName.GRE_COMPOSITE:
			return nest(['student', FormNames.studentTestScoreForm], GreCompositeInput);
		case ComponentName.GRE_QUANTITATIVE:
			return nest(['student', FormNames.studentTestScoreForm], GreQuantInput, {
				name: FormNames.greQuantitativeReasoning,
			});
		case ComponentName.GRE_VERBAL:
			return nest(['student', FormNames.studentTestScoreForm], GreVerbalInput, {
				name: FormNames.greVerbalReasoning,
			});
		case ComponentName.GRE_WRITING:
			return nest(['student', FormNames.studentTestScoreForm], GreWritingInput, {
				name: FormNames.greAnalyticalWriting,
			});
		case ComponentName.HIDDEN:
			return HiddenFlexInput;
		case ComponentName.HIGH_SCHOOL:
			return nest(['student', FormNames.studentHighSchoolDataForm], HighSchoolIndexSearch);
		case ComponentName.LEVEL_OF_DEGREE_SEEKING:
			return nest(['student', FormNames.studentInfoForm], LevelOfDegreeSeekingSelect, {
				name: FormNames.levelOfDegreeSeekingId,
			});
		case ComponentName.MAJOR:
			return nest(['collegePreferences'], MajorFormInput, {
				name: ListFormNames.majorCipCodes,
			});
		case ComponentName.MODALITY:
			return nest(['student', FormNames.studentInfoForm], ModalitySelect, {
				name: ListFormNames.modalityIds,
			});
		case ComponentName.MOST_RECENT_DEGREE:
			return nest(['student', FormNames.studentInfoForm], MostRecentDegree);
		case ComponentName.NAME:
			return nest(['student', FormNames.studentDataForm], NameForm);
		case ComponentName.OPEN_TO_TRANSFER:
			return nest(['student', FormNames.studentCollegeDataForm], OpenToTransfer);
		case ComponentName.PARENT:
			return nest(['student', ListFormNames.studentParentDataForms], ParentInput);

		case ComponentName.PHONE_NUMBER:
			return nest(['student', FormNames.studentDataForm], PhoneNumberInput);
		case ComponentName.SEEKING_MBA:
			return nest(['student', FormNames.studentInfoForm], SeekingMBA);
		case ComponentName.SEEKING_TEST_OPTIONAL_ADMISSIONS:
			return nest(['student', FormNames.studentInfoForm], SeekingTestOptionalAdmissions);
		case ComponentName.START_TERM:
			return nest(['student', FormNames.studentCollegeDataForm], StartTermInput);
		case ComponentName.START_TIMEFRAME:
			return nest(['student', FormNames.studentInfoForm], StartTimeframeSelect, {
				name: FormNames.startTimeframeId,
			});
		case ComponentName.STUDENT_TYPE:
			return nest(['student', FormNames.studentTypeId], RegStudentSelectType);
		case ComponentName.STUDY_ABROAD:
			return nest(['student', FormNames.studentInfoForm], StudyAbroadSelect, {
				name: FormNames.studyAbroadId,
			});
		case ComponentName.TEXT:
			return HeaderSubtext;
		case ComponentName.TOUR_PERSONALIZATION:
			return nest(['student', ListFormNames.studentTourPersonalizationForms], TourPersonalization);
		case ComponentName.TRANSFER_TERM:
			return nest(['student', FormNames.studentCollegeDataForm], TransferTermInput);
		case ComponentName.UNWEIGHTED_GPA:
			return nest(['student', FormNames.studentHighSchoolDataForm], UnweightedGpaInput, {
				name: FormNames.gpaUnweighted,
			});
		case ComponentName.WEIGHTED_GPA:
			return nest(['student', FormNames.studentHighSchoolDataForm], WeightedGpaInput, {
				name: FormNames.gpaWeighted,
			});
		case ComponentName.YEARS_EXPERIENCE:
			return nest(['student', FormNames.studentInfoForm], YearsExperienceInput, {
				name: FormNames.yearsOfWorkExperience,
			});
		case ComponentName.ZIP_CODE:
			return nest(['student', FormNames.studentAddressForm], ZipCodeInput, {
				name: FormNames.postalCode,
			});
		default:
			throw new Error('UNKNOWN COMPONENT TYPE');
	}
};

const componentUsesGrid = (name: ComponentName) => {
	switch (name) {
		case ComponentName.HIDDEN:
			return false;
		default:
			return true;
	}
};

const mapSize = (size?: 'small' | 'large') => {
	if (size === 'small') {
		return 6;
	}
	return 12;
};

const mapNameToGridComponent = (
	name: ComponentName,
	{ size, required, predicate, ...props }: Partial<FlexStepConfig>
): ComponentType<{ active: boolean; formState: ValidationState }> => {
	const Component = mapToComponent(name);
	const evaluateBuiltPredicate = predicate && evaluatePredicate(predicate);

	return ({ active, formState }) => {
		const formStateWhenPredicateExists = predicate && formState;
		const memoizedComponent = useMemo(() => {
			const shouldHide = predicate && !evaluateBuiltPredicate(formStateWhenPredicateExists);
			return componentUsesGrid(name) ? (
				<CssHideWhen component={Grid} item xs={mapSize(size)} when={shouldHide}>
					<Component
						{...props}
						required={required && active && !shouldHide}
						// @ts-ignore -- These 3 attributes may be extraneous on some components, and TS doesn't like that
						active={active}
						// @ts-ignore
						fullWidth
						// @ts-ignore
						variant={props?.inputStyle}
					/>
				</CssHideWhen>
			) : (
				<CssHideWhen when={shouldHide}>
					<Component
						{...props}
						required={required && active && !shouldHide}
						// @ts-ignore -- These 3 attributes may be extraneous on some components, and TS doesn't like that
						active={active}
						// @ts-ignore
						fullWidth
						// @ts-ignore
						variant={props?.inputStyle}
					/>
				</CssHideWhen>
			);
		}, [formStateWhenPredicateExists, active]);

		return memoizedComponent;
	};
};

export default mapNameToGridComponent;
