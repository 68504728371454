export enum AutomationNameDefault {
	anchorButton = 'anchor-button',
	administeringAgencyName = 'administering-agency-name',
	applicationButton = 'application-button',
	applicationElements = 'application-elements',
	awardAmount = 'award-amount',
	awardsGranted = 'awards-granted',
	birthDate = 'birth-date',
	birthDay = 'birth-date-day',
	birthMonth = 'birth-date-month',
	birthYear = 'birth-date-year',
	birthError = 'birth-date-error',
	consent = 'consent',
	consentYes = 'consent-yes',
	consentNo = 'consent-no',
	consentNoResponse = 'consent-no-response',
	deadline = 'deadline',
	detailList = 'detail-list',
	eligibilityCriteria = 'eligibility-criteria',
	emailInput = 'email-input',
	newEmailInput = 'new-email-input',
	verifyEmailInput = 'verify-email-input',
	passwordInput = 'password-input',
	newPasswordInput = 'new-password-input',
	verifyPasswordInput = 'verify-password-input',
	passwordVisibilityIcon = 'password-visibility',
	newPasswordVisibilityIcon = 'new-password-visibility',
	verifyPasswordVisibilityIcon = 'verify-password-visibility',
	gradDateYear = 'grad-date-year',
	gradDateMonth = 'grad-date-month',
	startTermYear = 'start-term-year',
	startTermSeason = 'start-term-season',
	errorRetry = 'error-page-retry',
	searchButton = 'search-button',
	alertButton = 'alert-button',
	messageButton = 'message-button',
	drawerMobile = 'drawer-mobile',
	drawerDesktop = 'drawer-desktop',
	firstNameInput = 'first-name-input',
	lastNameInput = 'last-name-input',
	avatar = 'avatar-image',
	editButton = 'edit-button',
	settingsButton = 'settings-button',
	registerButton = 'register-button',
	logo = 'cappex-logo',
	logout = 'logout',
	relationshipInput = 'relationship-input',
	validationList = 'validation-list',
	roleSelector = 'role-selector',
	sideNavList = 'navigation-list',
	sideNavHeader = 'navigation-header',
	sideNavMenuList = 'navigation-menu-list',
	stepCurrent = 'current-step',
	stepTotal = 'total-steps',
	highSchoolSearch = 'high-school-search',
	highSchoolSearchError = 'high-school-search-error',
	coins = 'coins',
	collegeCardList = 'college-card-list',
	graduateCollegeCardList = 'graduate-college-card-list',
	scholarshipCardList = 'scholarship-card-list',
	scholarshipName = 'scholarship-name',
	scholarshipCard = 'scholarship-card',
	quickView = 'quick-view-',
	overview = 'overview',
	chances = 'chances',
	fit = 'fit',
	fitChartText = 'fit-chart-text',
	fitInsufficientInfoText = 'fit-insufficient-info-text',
	fitPreferenceIcon = 'fit-preference-icon',
	fitPreferenceIconText = 'fit-preference-icon-text',
	howYouCompare = 'how-you-compare',
	collegeName = 'college-name',
	collegeLocation = 'college-location',
	rating = 'rating',
	readMore = 'read-more',
	readLess = 'read-less',
	price = 'price',
	acceptanceRate = 'acceptance-rate',
	institutionType = 'institution-type',
	gpaAndTestScoresButton = 'gpa-test-scores-button',
	multiLineWithTextHeader = 'multi-line-with-text-header',
	multiLineWithTextText = 'multi-line-with-text-text',
	familyLevelIncome = 'family-level-income',
	familyLevelIncomeInput = 'family-level-income-input',
	overviewListItem = 'overview-list-item',
	firstLine = 'first-line',
	secondLine = 'second-line',
	thirdLine = 'third-line',
	campusSetting = 'campus-setting',
	averageNetPrice = 'average-net-price',
	distanceFromHome = 'distance-from-home',
	inviteModal = 'invite-modal',
	submitButton = 'submit-button',
	gpaInput = 'gpa-input',
	weightedGpaInput = 'weighted-gpa-input',
	actCompositeInput = 'act-composite-input',
	actMathInput = 'act-math-input',
	actEnglishInput = 'act-english-input',
	actReadingInput = 'act-reading-input',
	actScienceInput = 'act-science-input',
	actWritingInput = 'act-writing-input',
	satCompositeInput = 'sat-composite-input',
	satReadWriteInput = 'sat-read-write-input',
	satMathInput = 'sat-math-input',
	preferenceCardTitle = 'preference-card-title',
	preferenceCardSubtext = 'preference-card-subtext',
	openPreferencesButton = 'open-preferences-button',
	clearPreferencesButton = 'clear-preferences-button',
	ctaTitle = 'cta-title',
	ctaDescription = 'cta-description',
	ctaButton = 'cta-button',
	heroImage = 'college-hero-image',
	smileyButtonLow = 'smiley-button-low',
	smileyButtonMedium = 'smiley-button-medium',
	smileyButtonHigh = 'smiley-button-high',
	addressInput1 = 'address-input-one',
	addressInput2 = 'address-input-two',
	cityInput = 'city-input',
	postalCodeinput = 'postal-code-input',
	countryInput = 'country-input',
	stateInput = 'state-input',
	regionInput = 'region-input',
	renewableAward = 'renewable-award',
	genderInput = 'gender-input',
	mostRecentDegree = 'most-recent-degree',
	enrolled = 'enrolled',
	seekingMba = 'seeking-mba',
	seekingTestOptionalAdmissions = 'seeking-test-optional-admissions',
	collegeApplicationStatusSelector = 'college-application-status-selector',
	collegeApplicationStatusOption = 'college-application-status-option',
	collegeApplicationStatusMenu = 'college-application-status-menu',
	collegeListCard = 'college-card',
	ethnicityCheckbox = 'ethnicity-checkbox',
	phoneNumber = 'phone-number',
	classRank = 'class-rank',
	courseRigor = 'course-rigor',
	chancesSectionHeader = 'chances-section-header',
	chancesSectionBodyText = 'chances-section-body-text',
	chancesSectionIllustrationImage = 'chances-section-illustration-image',
	chancesChartLabel = 'chances-chart-label',
	act = 'act',
	sat = 'sat',
	gpa = 'gpa',
	averageSat = 'average-sat',
	averageAct = 'average-act',
	averageGpa = 'average-gpa',
	fitSectionStudentDataAnyText = 'fit-section-student-data-any-text',
	fitSectionBodyText = 'fit-section-body-text',
	rollingAdmissions = 'rolling-admissions',
	fallDeadline = 'fall-regular-decision-deadline',
	earlyActionDeadline = 'early-action-deadline',
	earlyDecisionDeadline = 'early-decision-deadline',
	areaOfStudy = 'area-of-study',
	major = 'major',
	majorCount = 'major-count',
	majorsOffered = 'majors-offered',
	majorsNotOffered = 'majors-not-offered',
	majorsTitle = 'majors-title',
	costAfterAid = 'cost-after-aid',
	bachelorCost = 'bachelor-cost-per-credit-hour',
	costBeforeAid = 'cost-before-aid',
	tuition = 'tuition',
	otherExpenses = 'other-expenses',
	studentsAtCollege = 'students-at-college',
	states = 'states',
	countries = 'countries',
	inState = 'in-state',
	outOfState = 'out-of-state',
	inStateGraph = 'in-state-graph',
	women = 'women',
	men = 'men',
	genderGraph = 'gender-graph',
	asianPacificIslander = 'asian-pacific-islander',
	africanAmerican = 'african-american',
	hispanic = 'hispanic',
	otherEthnicity = 'other-ethnicity',
	nativeAmerican = 'native-american',
	white = 'white',
	updateYourInfo = 'update-your-info',
	comparisonChartAverage = 'comparison-chart-average',
	comparisonChartIndivival = 'comparision-chart-individual',
	averageTotalDebt = 'average-total-debt',
	averageMonthlyLoanPayment = 'average-monthly-loan-payment',
	averageSalary = 'average-salary',
	averageMonthlySalary = 'average-monthly-salary',
	afterGraduation = 'after-graduation',
	averageGrantsForFamilyIncome = 'average-grants-for-family-income',
	studentsReceivingGrants = 'students-receiving-grants',
	averageGrantAmount = 'average-grant-amount',
	percentTakingLoans = 'percent-students-taking-loans',
	averageLoanAmount = 'average-loan-amount',
	applicationFee = 'application-fee',
	homegrownApplication = 'homegrown-application',
	coalitionApplication = 'coalition-application',
	commonApplication = 'common-application',
	stateSpecificApplication = 'state-specific-application',
	universalApplication = 'universal-application',
	collegeCarousel = 'college-carousel',
	collegeCarouselLeftArrowSkeleton = 'college-carousel-left-arrow-skeleton',
	collegeCarouselRightArrowSkeleton = 'college-carousel-right-arrow-skeleton',
	collegeCarouselLeftArrow = 'college-carousel-left-arrow',
	collegeCarouselRightArrow = 'college-carousel-right-arrow',
	collegeCarouselCardSkeleton = 'college-carousel-card-skeleton',
	collegeCarouselCardHeroSkeleton = 'college-carousel-card-hero-skeleton',
	collegeCarouselCardTitleSkeleton = 'college-carousel-card-title-skeleton',
	collegeCarouselCard = 'college-carousel-card',
	collegeCarouselCardHero = 'college-carousel-card-hero',
	collegeCarouselSave = 'college-carousel-save',
	collegeCarouselCardTitle = 'college-carousel-card-title',
	collegeCarouselShowMore = 'college-carousel-show-more',
	collegeCarouselNoResults = 'college-carousel-no-results',
	dataFlowLeftContainer = 'data-flow-left-container',
	dataFlowRightContainer = 'data-flow-right-container',
	seeRecommendations = 'see-recommendations',
	scholarshipCarouselCard = 'scholarship-carousel-card',
	findSchools = 'find-schools',
	genericCardCarouselSkeleton = 'generic-card-carousel-skeleton',
	genericCardGridSkeleton = 'generic-card-grid-skeleton',
	outreachCarouselRightArrow = 'outreach-carousel-right-arrow',
	outreachCarouselLeftArrow = 'outreach-carousel-left-arrow',
	outreachCarouselRightArrowSkeleton = 'outreach-carousel-right-arrow-skeleton',
	outreachCarouselLeftArrowSkeleton = 'outreach-carousel-left-arrow-skeleton',
	outreachCarouselCardHero = 'outreach-coursel-card-hero',
	outreachCarouselCard = 'outreach-carousel-card',
	outreachCarouselCardSchoolName = 'outreach-carousel-card-school-name',
	outreachCarouselCardSchoolLocation = 'outreach-carousel-card-school-name',
	outreachCarouselCardPreferenceOne = 'outreach-carousel-card-preference-one',
	outreachCarouselCardPreferenceTwo = 'outreach-carousel-card-preference-two',
	outreachCarouselCardPreferenceThree = 'outreach-carousel-card-preference-three',
	outreachCarouselCardPreferenceFour = 'outreach-carousel-card-preference-four',
	outreachCarouselCardDismiss = 'outreach-carousel-card-dismiss',
	outreachCarouselCardSave = 'outreach-carousel-card-save',
	paOfferCard = 'pa-offer-card',
	paOfferCardHero = 'pa-offer-card-hero',
	paOfferCardLogo = 'pa-offer-card-logo',
	paOfferCardSignUpButton = 'pa-offer-card-sign-up-button',
	paOfferCardHeaderText = 'pa-offer-card-header-text',
	paOfferCardBodyText = 'pa-offer-card-body-text',
	paOfferCardMarkdownBodyText = 'pa-offer-card-markdown-body-text',
	paOfferModal = 'pa-offer-modal',
	paOfferModalLogo = 'pa-offer-modal-logo',
	paOfferModalHeaderText = 'pa-offer-modal-header-text',
	paOfferModalBodyText = 'pa-offer-modal-body-text',
	paOfferModalDisclaimerText = 'pa-offer-modal-disclaimer-text',
	paOfferModalBodyParagraph = 'pa-offer-modal-body-paragraph',
	paOfferModalCloseButton = 'pa-offer-modal-close-button',
	paOfferModalSubmitButton = 'pa-offer-modal-submit-button',
	paOfferInputText = 'pa-offer-input-text',
	paOfferInputEmail = 'pa-offer-input-email',
	paOfferInputName = 'pa-offer-input-name',
	paOfferInputSelect = 'pa-offer-input-select',
	paOfferInputCheckbox = 'pa-offer-input-checkbox',
	paOfferInputPhone = 'pa-offer-input-phone',
	paOfferInputLeadId = 'pa-offer-input-leadid',
	paOfferLeadidTcpaDisclosure = 'pa-offer-leadid-tcpa-disclosure',
	paOfferInputHidden = 'pa-offer-input-hidden',
	paInterstitialContinueButton = 'pa-interstitial-continue-button',
	paEmptyInterstitialContinueButton = 'pa-empty-interstitial-continue-button',
	paOfferInterstitialModal = 'pa-offer-interstitial-modal',
	paOfferSubHeader = 'pa-offer-sub-header',
	paOfferModalParagraph = 'pa-offer-modal-paragraph',
	actionHeaderBar = 'action-header-bar',
	parentEmailInput = 'parent-email-input',
	parentFirstNameInput = 'parent-first-name-input',
	parentLastNameInput = 'parent-last-name-input',
	paFeaturedOffersContinueButton = 'pa-featured-offers-continue-button',
	firstGenerationStudentCheckbox = 'first-generation-student-checkbox',
	numberUndergraduateStudents = 'number-undergraduate-students',
	schoolSize = 'school-size',
	studentFacultyRatio = 'student-faculty-ratio',
	nationalStudentFacultyRatio = 'national-student-faculty-ratio',
	religionInput = 'religion-input',
	psatNmsqtInput = 'psat-nmsqt-input',
	preActInput = 'pre-act-input',
	collegeTagline = 'college-tagline',
	collegeContentBlock = 'college-content-block',
	fourYearGradRate = 'four-year-grad-rate',
	nationalFourYearGradAvg = 'national-four-year-grad-avg',
	sixYearGradRate = 'six-year-grad-rate',
	nationalSixYearGradAvg = 'national-six-year-grad-avg',
	graduationRatesInfo = 'graduation-rates-info',
	graduationRateHeader = 'graduation-rate-header',
	greetingHeader = 'greeting-header',
	greetingSubHeader = 'greeting-sub-header',
	greetingIcon = 'greeting-icon',
	matchModalImg = 'match-modal-img',
	matchCarouselRightArrow = 'match-carousel-right-arrow',
	matchCarouselLeftArrow = 'match-carousel-left-arrow',
	matchCarouselRightArrowSkeleton = 'match-carousel-right-arrow-skeleton',
	matchCarouselLeftArrowSkeleton = 'match-carousel-left-arrow-skeleton',
	matchCarouselCardHero = 'match-coursel-card-hero',
	matchCarouselCard = 'match-carousel-card',
	matchCarouselCardSchoolName = 'match-carousel-card-school-name',
	matchCarouselCardSchoolLocation = 'match-carousel-card-school-name',
	matchCarouselCardPreferenceOne = 'match-carousel-card-preference-one',
	matchCarouselCardPreferenceTwo = 'match-carousel-card-preference-two',
	matchCarouselCardPreferenceThree = 'match-carousel-card-preference-three',
	matchCarouselCardPreferenceFour = 'match-carousel-card-preference-four',
	matchCarouselCardDismiss = 'match-carousel-card-dismiss',
	matchCarouselCardSave = 'match-carousel-card-save',
	masonryGridColumn = 'masonry-grid-column',
	masonryGridRow = 'masonry-grid-row',
	exploreCollegesLink = 'explore-colleges-link',
	studentAcademicInfoLink = 'student-academic-info-link',
	collegeListLink = 'college-list-link',
	totalReachSchools = 'college-summary-card-total-reach-schools',
	totalTargetSchools = 'college-summary-card-total-target-schools',
	totalLikelySchools = 'college-summary-card-total-likely-schools',
	totalUncategorizedSchools = 'college-summary-card-total-uncategorized-schooles',
	achievementIcon = 'achievement-icon',
	achievementCategory = 'achievement-category',
	achievementTitle = 'achievement-title',
	achievementContainer = 'achievement-container',
	achievementsListLink = 'achievements-list-link',
	achievementListCardIcon = 'achievement-list-card-icon',
	achievementListCardHiddenIcon = 'achievement-list-card-hidden-icon',
	achievementListCardEmptyStateText = 'achievement-list-card-empty-state-text',
	quizModal = 'quiz-result-modal',
	selectableImage = 'selectable-image',
	startTimeframeInput = 'start-timeframe-input',
	desiredCompletionTimeframeInput = 'desired-completion-timeframe-input',
	levelOfDegreeSeekingInput = 'level-of-degree-seeking-input',
	hsGpaInput = 'hs-gpa-input',
	collegeGpaInput = 'college-gpa-input',
	selectableText = 'selectable-text',
	gmatTotalInput = 'gmat-total-input',
	greQuantitativeInput = 'gre-quant-input',
	greVerbalInput = 'gre-verbal-input',
	greWritingInput = 'gre-writing-input',
	yearsOfWorkInput = 'years-experience-input',
	magicLinkBox = 'magic-link-box',
	easyApplyButton = 'easy-apply-button',
	exploreTransferButton = 'explore-transfer-button',
	studentView = 'student-view',
	percentPartTimeUndergrads = 'percent-part-time-undergrad',
	percentUndergradsOver25 = 'percent-undergrads-over-25',
	percentOnlineStudents = 'percent-online-students',
	attendReligiousInput = 'open-to-religious-input',
	studyAbroadInput = 'study-abroad-input',
	undecidedMajor = 'undecided-major',
	jitModal = 'jit-modal',
	tourPersonalization = 'tour-personalization',
}

export enum AutomationNameGeneric {
	form = 'form',
	menuItem = 'menu-item',
	image = 'image',
	link = 'link',
	card = 'card',
	chips = 'chips',
	burgerButton = 'burger-button',
	chipButton = 'chip-button',
	searchInput = 'search-input',
	icon = 'icon',
	inputIcon = 'input-icon',
	inputIconButton = 'input-icon-button',
	closeButton = 'close-button',
	submitButton = 'submit-button',
	tab = 'tab',
	doneButton = 'done-button',
	toggleButton = 'toggle-button',
	formSelect = 'form-select',
	wrapper = 'wrapper',
	radioButton = 'radio-button',
	checkbox = 'checkbox',
	headerText = 'header-text',
	headerIcon = 'header-icon',
	helperText = 'helper-text',
	list = 'list',
	listItem = 'list-item',
	text = 'text',
	date = 'date',
	score = 'score',
	section = 'section',
	secondary = 'secondary',
	expand = 'expand',
	collapse = 'collapse',
	chart = 'chart',
	label = 'label',
}

export enum AutomationState {
	on = '-on',
	off = '-off',
	error = '-error',
}

export const AutomationUrlParam = 'automation';
